import i18n from '../i18n'

const required = (value) => {
  return !!value || i18n.t('forms.validation.required')
}

export const rules = Object.freeze({
  required,

  name: [required],

  email: [
    required,
    (value) => {
      // In accordance w/ RCF2822 specifications
      const pattern =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      return pattern.test(value) || i18n.t('forms.validation.email')
    },
  ],

  password: [
    required,
    (value) => {
      // 8+ characters, 1 uppercase, 1 lowercase, and 1 number, no spaces
      const pattern =
        /^(?!.* )(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
      return pattern.test(value) || i18n.t('forms.validation.password')
    },
  ],

  projectDescription: [
    (value) => {
      if (value) {
        const isValueValid =
          value === '' || (value?.length >= 5 && value?.length <= 160)
        return isValueValid || i18n.t('forms.validation.projectDescription')
      } else {
        return true
      }
    },
  ],

  projectClient: [
    (value) => {
      if (value) {
        const isValueValid =
          value === '' || (value?.length >= 5 && value?.length <= 40)
        return isValueValid || i18n.t('forms.validation.projectClient')
      } else {
        return true
      }
    },
  ],

  projectTitle: [
    required,
    (value) => {
      return (
        value === '' ||
        (value.length >= 5 && value.length <= 40) ||
        i18n.t('forms.validation.projectTitle')
      )
    },
  ],

  helpDescription: [
    required,
    (value) => {
      if (value) {
        return (
          (value.length >= 15 && value.length <= 1000) ||
          i18n.t('forms.validation.helpDescription')
        )
      }
      return false
    },
  ],

  images: [
    (values) => {
      if (!values || !values.length) {
        return false
      }

      if (values.length > 100) {
        return i18n.t('forms.validation.imagesCount')
      }

      if (
        values.some(
          (value) =>
            !['image/jpg', 'image/jpeg', 'image/png'].includes(value.type),
        )
      ) {
        return i18n.t('forms.validation.imagesType')
      }

      if (values.some((value) => value.size > 10000000)) {
        return i18n.t('forms.validation.imagesSize')
      }

      return true
    },
  ],

  iriImages: [
    (values) => {
      if (!values || !values.length) {
        return false
      }

      if (
        values.some(
          (value) =>
            !['image/jpg', 'image/jpeg', 'image/png'].includes(value.type),
        )
      ) {
        return i18n.t('forms.validation.imagesType')
      }

      return true
    },
  ],
})
