import { risk as riskEnum } from './enum'

export const defaultGeolocation = [45.434387917659976, -73.45894506329884]
export const defaultGeoJsonPoint = {
  type: 'Point',
  coordinates: [-73.45894506329884, 45.434387917659976],
}
export const defaultSpecificationRange = [-3, -2, -1, 0, 1, 2, 3]

export const colorMinimum = {
  yellow: 33,
  green: 66,
}
export const colorHexValues = {
  red: '#e61b1e',
  lightRed: '#f5a4a5',
  green: '#00a97a',
  lightGreen: '#dffae2',
  yellow: '#f8bd22',
  lightYellow: '#fce5a7',
  grey: '#0009',
  lightGrey: '#999999',
}
export function riskToPercentile(risk) {
  switch (risk) {
    case riskEnum.LR:
      return 100
    case riskEnum.AR:
      return colorMinimum.green - 1
    case riskEnum.HR:
      return colorMinimum.yellow - 1
    default:
      return -1
  }
}
