<template>
  <material-card
    outlined
    class="mt-6"
  >
    <div>
      <div class="subtitle-1 ml-2">{{ $t('views.image-upload.bank') }}</div>
      <div class="text-caption text--disabled ml-2"><br /></div>
    </div>
    <v-row>
      <v-col
        v-for="image in images"
        :key="image._id"
        cols="12"
        sm="6"
        md="4"
        xl="2"
      >
        <v-card class="thumbnail-card">
          <v-img
            :src="image.src"
            class="white--text align-end"
            height="350"
            width="350"
          >
            <v-card-title>
              <div class="text-truncate">
                {{ image.originalFilename }}
              </div>
            </v-card-title>
          </v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="$refs.imageDialog.show(image)"
            >
              <v-icon>$fas fa-expand-alt</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="remove(image._id)"
            >
              <v-icon>$fas fa-trash</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          v-if="hasMore"
          outlined
          block
          @click="onScrollBottom"
          >{{ $t('views.image-upload.get-more-action') }}
          <v-icon
            right
            dark
            >$fas fa-plus
          </v-icon></v-btn
        >
      </v-col>
    </v-row>
    <ResizableImageDialog ref="imageDialog" />
  </material-card>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import ResizableImageDialog from '@/components/DashViews/Image/ResizableImageDialog'

export default {
  name: 'ImageViewer',
  components: {
    ResizableImageDialog,
  },
  data() {
    return {
      images: [],
      hasMore: false,
      dialog: false,
      isLoading: true,
      src: '',
      height: 350,
    }
  },

  computed: {
    ...mapState(['idproject']),
    ...mapGetters({ getByProject: 'images/getByProject' }),
  },

  watch: {
    idproject() {
      this.hasMore = true
      this.refreshImages()
    },
  },

  created() {
    this.refreshImages()
  },
  methods: {
    ...mapActions({
      getImages: 'images/get',
      getMore: 'images/getMore',
      deleteImage: 'images/delete',
    }),

    async refreshImages() {
      const { hasMore } = await this.getImages(this.idproject)
      this.hasMore = hasMore
      this.images = []
      await this.updateImages()
    },

    async onScrollBottom() {
      const { hasMore } = await this.getMore(this.idproject)
      this.hasMore = hasMore
      await this.updateImages()
    },

    async updateImages() {
      const currentIds = this.images.map((_i) => _i._id)
      const images = this.getByProject(this.idproject)
      this.images.push(...images.filter((_i) => !currentIds.includes(_i._id)))

      await Promise.all(
        this.images.map(async (_image, index) => {
          if (_image.src) {
            return
          }
          try {
            const { data } = await axios.get(
              `/images/thumbnail/${_image._id}`,
              { responseType: 'blob' },
            )
            _image.src = URL.createObjectURL(data)
            Vue.set(this.images, index, _image)
          } catch (err) {
            console.warn(err)
          }
        }),
      )
    },
    async remove(imageId) {
      if (confirm(this.$t('views.image-upload.delete'))) {
        await this.deleteImage(imageId)
        this.images.splice(
          this.images.findIndex((_i) => _i._id === imageId),
          1,
        )
      }
    },
  },
}
</script>
