<template>
  <material-card
    outlined
    class="fill-height"
  >
    <div class="callout">
      <div class="subtitle-1 ml-2">
        {{ $t('views.charts.tableDataPoint.title') }}
      </div>
      <div class="text-caption text--disabled ml-2">
        {{ $t('views.charts.tableList.description') }}
      </div>
    </div>
    <v-data-table
      class="v-table-pointer"
      :headers="calibrationHeader"
      :items="segments"
      item-key="id"
      fixed-header
      style="overflow: auto"
      height="100%"
      hide-default-footer
      show-expand
      single-expand
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      @item-expanded="(event) => $emit('item-expanded', event)"
    >
      <template #headerCell="{ header }">
        <span
          class="subheading font-weight-light text-success text--darken-3"
          v-text="header.text"
        />
      </template>
      <template #[`item.date`]="{ item }">
        {{ $helpers.date.formatDatetime(item.date) }}
      </template>
      <template #[`item.deviceVehicle`]="{ item }">
        {{
          deviceVehicleDescription()(item.device, item.vehicle) ||
          $t('views.iri-management.not-available')
        }}
      </template>
      <template #expanded-item="{ item }">
        <td
          :colspan="12"
          class="py-4"
        >
          <v-data-table
            dense
            :headers="iriHeaders"
            :items="item.iriSections"
            :hide-default-footer="true"
            disable-pagination
            @mouseover:row="(event, { item }) => selectSection(item)"
            @click:row="(event, { item }) => selectSection(item)"
            @mouseleave:row="(event, { item }) => unselectSection(item)"
          >
            <template #[`item.iri`]="{ iriItem }">
              {{ iriItem.iri ? iriItem.iri.toFixed(2) : '-' }}
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </material-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CalibrationTable',
  props: {
    segments: {
      type: [Array],
      required: true,
    },
  },

  data() {
    return {
      calibrationHeader: [
        { text: this.$t('enums.headers.date'), value: 'date', sortable: true },
        {
          text: this.$t('views.iri-management.upload-form.device-vehicle-pair'),
          value: 'deviceVehicle',
        },
        {
          text: this.$t(
            'views.iri-management.calibration-list-header.calibration',
          ),
          value: 'iriOffset',
        },
      ],
      iriHeaders: [
        {
          text: this.$t('enums.headers.IRI'),
          value: 'iri',
          align: 'left',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('iri', ['device', 'vehicle']),
  },
  methods: {
    ...mapGetters('iri', ['deviceVehicleDescription']),
    selectSection(item) {
      this.$emit('section-selected', item)
    },
    unselectSection() {
      this.$emit('section-selected', undefined)
    },
  },
}
</script>
