<template>
  <v-wait for="isCalibrationUpdating">
    <core-spinner slot="waiting" />
    <title-bar />
    <div class="grid-container">
      <IriMap
        class="calibration-map"
        :map-bindings="mapBindings"
      >
        <template #map-content>
          <iri-run :runs="calibrationGeo" />
          <section-highlight :section="selectedSectionGeo" />
        </template>
      </IriMap>
      <material-card
        outlined
        class="fill-height calibration-form"
      >
        <v-form ref="calibrationForm">
          <v-select
            v-model="selectedType"
            :items="translatedFileType"
            :label="$t('views.upload.placeholder')"
            :rules="[(v) => !!v || $t('api-error.iri.datapoint-types-error')]"
            class="select-no-border"
          />
          <v-file-input
            v-model="files"
            :accept="validFileExtensions"
            :rules="[
              (v) =>
                class1Types.includes(selectedType)
                  ? (!!v && v.length === 1) ||
                    $t('api-error.iri.calibration-file-error')
                  : (!!v && v.length === 3) ||
                    $t('api-error.iri.calibration-files-error'),
            ]"
            :label="$t('views.iri-management.upload-form.calibration-files')"
            :clearable="false"
            multiple
            counter
            small-chips
            class="px-2"
          />
          <v-checkbox
            v-if="isBenchmark"
            v-model="isPublic"
            :label="$t('views.iri-management.upload-form.is-public')"
            dense
          ></v-checkbox>
          <div
            v-if="isCalibration"
            class="select-with-add"
          >
            <v-select
              v-model="selectedVehicle"
              :items="
                vehicles.map((_v) => ({
                  text: `${_v.make} ${_v.model} - ${_v.licencePlate}`,
                  value: _v._id,
                }))
              "
              :label="$t('views.iri-management.upload-form.vehicle')"
              class="select-no-border"
              :rules="[
                (v) => isBenchmark || !!v || $t('api-error.iri.vehicle-error'),
              ]"
            />
            <v-dialog
              v-model="vehicleDialog"
              persistent
              max-width="600px"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>$fas fa-plus</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{
                    $t('views.iri-management.vehicle-form.title')
                  }}</span>
                </v-card-title>
                <v-card-text>
                  <v-form
                    ref="vehicleForm"
                    lazy-validation
                  >
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="vehicleMake"
                            :label="
                              $t('views.iri-management.vehicle-form.make')
                            "
                            required
                            :rules="[
                              (v) =>
                                !!v ||
                                $t(
                                  'views.iri-management.vehicle-form.make-error',
                                ),
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="vehicleModel"
                            :label="
                              $t('views.iri-management.vehicle-form.model')
                            "
                            required
                            :rules="[
                              (v) =>
                                !!v ||
                                $t(
                                  'views.iri-management.vehicle-form.model-error',
                                ),
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="vehicleLicencePlate"
                            :label="
                              $t(
                                'views.iri-management.vehicle-form.licence-plate',
                              )
                            "
                            required
                            :rules="[
                              (v) =>
                                !!v ||
                                $t(
                                  'views.iri-management.vehicle-form.licence-plate-error',
                                ),
                            ]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="vehicleDialog = false"
                  >
                    {{ $t('views.iri-management.dialog-cancel') }}
                  </v-btn>
                  <v-btn
                    outlined
                    color="primary"
                    @click="saveVehicle"
                  >
                    {{ $t('views.iri-management.dialog-save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div
            v-if="isCalibration"
            class="select-with-add"
          >
            <v-select
              v-model="selectedDevice"
              :items="
                devices.map((_d) => ({
                  text: `${_d.externalIdentifier} - ${_d.type}`,
                  value: _d._id,
                }))
              "
              :label="$t('views.iri-management.upload-form.device')"
              class="select-no-border"
              :rules="[
                (v) => isBenchmark || !!v || $t('api-error.iri.device-error'),
              ]"
            />
            <v-dialog
              v-model="deviceDialog"
              persistent
              max-width="600px"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>$fas fa-plus</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{
                    $t('views.iri-management.device-form.title')
                  }}</span>
                </v-card-title>
                <v-card-text>
                  <v-form
                    ref="deviceForm"
                    lazy-validation
                  >
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="selectedDeviceType"
                            :label="
                              $t('views.iri-management.device-form.device-type')
                            "
                            required
                            :items="deviceTypes"
                            :rules="[
                              (v) =>
                                !!v ||
                                $t(
                                  'views.iri-management.device-form.device-type-error',
                                ),
                            ]"
                            class="select-no-border"
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="deviceExternalIdentifier"
                            :label="
                              $t(
                                'views.iri-management.device-form.external-identifier',
                              )
                            "
                            required
                            :rules="[
                              (v) =>
                                !!v ||
                                $t(
                                  'views.iri-management.device-form.external-identifier-error',
                                ),
                            ]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="deviceDialog = false"
                  >
                    {{ $t('views.iri-management.dialog-cancel') }}
                  </v-btn>
                  <v-btn
                    outlined
                    @click="saveDevice"
                  >
                    {{ $t('views.iri-management.dialog-save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <v-btn
            color="primary"
            @click="sendFile"
          >
            {{ $t('views.upload.confirmation') }}
          </v-btn>
        </v-form>
        <v-btn
          class="mt-4"
          @click="$router.push($route.path + '/nearby-benchmarks')"
        >
          {{ $t('views.iri-management.calibration-section') }}
        </v-btn>
        <v-btn
          class="mt-4"
          @click="$router.push($route.path + '/calibrations')"
        >
          {{ $t('views.iri-management.calibration-list') }}
        </v-btn>
      </material-card>
      <CalibrationTable
        class="calibration-table"
        :segments="calibrations"
        @item-expanded="rowExpanded"
        @section-selected="sectionSelected"
      />
    </div>
  </v-wait>
</template>

<script>
import CalibrationTable from './CalibrationTable.vue'
import IriMap from '@/components/DashViews/Charts/Maps/IriMap.vue'
import IriRun from '@/components/core/map/IriRun.vue'
import SectionHighlight from '@/components/core/map/SectionHighlight.vue'
import TitleBar from '@/components/core/TitleBar'

import { iriBenchmarkPathToFeatureCollection } from '@/utils/datapoint'
import { deviceType, iriFileTypes, risk } from '@/utils/enum'
import { toSelectItems } from '@/utils/i18n'
import { defaultMapBindings } from '@/utils/map'
import { romdasPreProcessing } from '@/utils/mdbUtil'
import { mapActions, mapMutations, mapState } from 'vuex'
import { geoJSON } from 'leaflet'

export default {
  name: 'IRICalibrationsManagementDashboard',
  components: {
    CalibrationTable,
    IriMap,
    IriRun,
    SectionHighlight,
    TitleBar,
  },

  data() {
    return {
      mapBindings: defaultMapBindings(),
      selectedSource: null,
      selectedSection: undefined,
      types: [
        iriFileTypes.romdas,
        iriFileTypes.roadBump,
        iriFileTypes.roadroid,
      ],
      class1Types: [iriFileTypes.romdas],
      translatedFileType: toSelectItems(
        {
          1: iriFileTypes.romdas,
          2: iriFileTypes.roadroid,
        },
        'enums.iri-file-types',
      ),

      files: null,
      isPublic: false,
      selectedType: null,
      selectedVehicle: null,
      selectedDevice: null,

      vehicleDialog: false,
      vehicleMake: '',
      vehicleModel: '',
      vehicleLicencePlate: '',

      deviceDialog: false,
      deviceTypes: toSelectItems(deviceType, 'enums.device-type'),
      selectedDeviceType: null,
      deviceExternalIdentifier: '',
    }
  },

  computed: {
    ...mapState('iri', ['calibrations', 'devices', 'vehicles']),

    validFileExtensions() {
      switch (this.selectedType) {
        case iriFileTypes.romdas:
          return '.mdb'
        case iriFileTypes.roadBump:
          return '.csv'
        case iriFileTypes.roadroid:
        case iriFileTypes.roadroidSingle:
          return '.txt'
        default:
          return ''
      }
    },
    isBenchmark() {
      return this.selectedType === iriFileTypes.romdas
    },
    isCalibration() {
      return this.selectedType && this.selectedType !== iriFileTypes.romdas
    },
    calibrationGeo() {
      return iriBenchmarkPathToFeatureCollection(this.calibrations)
    },
    selectedSectionGeo() {
      if (!this.selectedSection) {
        return null
      } else {
        return {
          type: 'Feature',
          properties: {
            risk: risk.NA,
          },
          geometry: {
            type: 'LineString',
            coordinates: [
              this.selectedSection.startLocation.coordinates,
              this.selectedSection.endLocation.coordinates,
            ],
          },
        }
      }
    },
  },

  async created() {
    this.$wait.start('isCalibrationUpdating')
    this.updateCalibration([])
    await Promise.all([
      this.getCalibrations({ disabled: false }),
      this.getVehicles(),
      this.getDevices(),
    ])
    this.setBounds()
    this.$wait.end('isCalibrationUpdating')
  },

  methods: {
    ...mapActions('iri', [
      'createDevice',
      'createVehicle',
      'getCalibrations',
      'getDevices',
      'getVehicles',
      'uploadBenchmark',
      'uploadCalibration',
    ]),
    ...mapMutations({
      updateCalibration: 'iri/update',
    }),
    ...mapMutations(['showSuccess']),

    async saveVehicle() {
      if (!this.$refs.vehicleForm.validate()) return

      const { id: newVehicleId } = await this.createVehicle({
        licencePlate: this.vehicleLicencePlate,
        make: this.vehicleMake,
        model: this.vehicleModel,
      })

      await this.getVehicles()
      this.selectedVehicle = newVehicleId
      this.vehicleDialog = false
    },

    async saveDevice() {
      if (!this.$refs.deviceForm.validate()) return

      const { id: newDeviceId } = await this.createDevice({
        type: this.selectedDeviceType,
        externalIdentifier: this.deviceExternalIdentifier,
      })

      await this.getDevices()
      this.selectedDevice = newDeviceId
      this.deviceDialog = false
    },

    async sendFile() {
      let data
      let file

      if (!this.$refs.calibrationForm.validate()) return

      try {
        if (this.selectedType === iriFileTypes.romdas) {
          data = await romdasPreProcessing(this.files[0])
          file = [new Blob([JSON.stringify(data)])]

          await this.uploadBenchmark({
            files: file,
            type: this.selectedType,
            isPublic: this.isPublic,
            isReversed: data?.isReversed || false,
          })
        } else {
          await this.uploadCalibration({
            files: this.files,
            type: this.selectedType,
            vehicle: this.selectedVehicle,
            device: this.selectedDevice,
          })
        }

        this.showSuccess(
          this.$t('views.iri-management.upload-form.upload-file-successful'),
        )
      } finally {
        this.$refs.calibrationForm.reset()

        this.isPublic = false
        this.submitButtonLoading = false

        this.setBounds()
      }
    },

    rowExpanded({ item, value }) {
      const source = item.source
      const isExpanded = value

      if (isExpanded) {
        this.selectedSource = source
      } else {
        this.selectedSource = null
      }
    },

    sectionSelected(item) {
      this.selectedSection = item
    },

    setBounds() {
      const bounds = geoJSON(this.calibrationGeo).getBounds()

      if (bounds && bounds.isValid()) {
        this.mapBindings.bounds = bounds
      }
    },
  },
}
</script>

<style scoped>
.calibration-map {
  grid-row: 1 / span 6;
  grid-column: 1 / span 9;
}
.calibration-form {
  grid-row: 1 / span 12;
  grid-column: 10 / span 3;
}
.calibration-table {
  grid-row: 7 / span 6;
  grid-column: 1 / span 9;
}
</style>
