<template>
  <v-dialog v-model="open">
    <v-card style="overflow: hidden">
      <v-row>
        <v-col>
          <v-img
            :class="(open && !selected ? 'blurry' : '') + ' align-center'"
            :src="src"
            class="grey darken-4"
          />
        </v-col>
        <v-col justify="center">
          <v-btn
            icon
            depressed
            :disabled="previousDisabled"
            class="inline"
            @click="previous"
            ><v-icon>$fas fa-caret-left</v-icon></v-btn
          >
          <span class="mx-4"
            >{{ selectedIndex + 1 }} / {{ items.length || 1 }}</span
          >
          <v-btn
            icon
            depressed
            :disabled="nextDisabled"
            class="inline"
            @click="next"
            ><v-icon>$fas fa-caret-right</v-icon></v-btn
          >
          <span class="mx-4"
            >Section: {{ selected?.startStation }} -
            {{ selected?.endStation }}</span
          >
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ImageViewerPopup',

  data: () => ({
    items: [],
    open: false,
    selected: null,
    selectedIndex: 0,
    src: '',
  }),

  computed: {
    nextDisabled() {
      return (
        this.items.length === 0 || this.selectedIndex >= this.items.length - 1
      )
    },
    previousDisabled() {
      return this.items.length === 0 || this.selectedIndex <= 0
    },
  },

  methods: {
    openSingle(image) {
      this.items = []
      this.selectedIndex = 0

      this.selected = image
      this.src = `${process.env.VUE_APP_BASE_URL}/images/${image._id}`
      this.open = true
    },

    openMultiple(images, index) {
      if (index >= images.length || index < 0) return
      this.items = images
      this.selectedIndex = index

      this.selected = images[index]
      this.src = `${process.env.VUE_APP_BASE_URL}/images/${images[index]._id}`
      this.open = true
    },
    next() {
      if (this.nextDisabled) return

      this.selectedIndex++
      this.selected = this.items[this.selectedIndex]
      this.src = `${process.env.VUE_APP_BASE_URL}/images/${this.selected._id}`
    },
    previous() {
      if (this.previousDisabled) return

      this.selectedIndex--
      this.selected = this.items[this.selectedIndex]
      this.src = `${process.env.VUE_APP_BASE_URL}/images/${this.selected._id}`
    },
  },
}
</script>
