import resetState from '@/utils/resetState'
import { initialImagesState } from './state'
import { set } from '@/utils/vuex'

export default {
  clear: (state) => (state.items = []),

  append: (state, items) => {
    const currentIds = state.items.map((_i) => _i._id)
    items
      .filter((_t) => !currentIds.includes(_t._id))
      .forEach((_i) => state.items.push(_i))
  },

  removeOne: (state, imageId) => {
    state.items.splice(
      state.items.findIndex((_i) => _i._id === imageId),
      1,
    )
  },

  setImages: set('items'),

  clearState(state) {
    resetState(state, initialImagesState)
  },
}
