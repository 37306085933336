// https://vuex.vuejs.org/en/actions.html
import axios from 'axios'
import * as datapointUtil from '@/utils/datapoint'
import modules from './modules/index'
import {
  isValidYearMonthString,
  sortDatapointDates,
  toMonthYearDay,
} from '@/utils/date'
import { markerToGeoJson } from '@/utils/map'
import { notificationsFromKpis } from '@/utils/notifications'
import dayjs from 'dayjs'
import { differenceBy, pick, uniq } from 'lodash'

// The login action passes vuex commit helper that we will use to trigger mutations.
export default {
  async login({ commit }, userData) {
    try {
      const response = await axios.post('/auth/login', {
        email: userData.username,
        password: userData.password,
      })

      commit('login')
      commit('user', response.data.user)

      if (response.data.user.languagePreference) {
        commit('updateLanguage', response.data.user.languagePreference)
      }

      return response
    } catch (err) {
      console.error(err)
    }
  },

  async authenticate({ commit }) {
    const response = await axios.get('/users/me')
    const user = response?.data

    if (!user) return

    commit('login')
    commit('user', user)
    return user
  },

  logout({ commit }) {
    axios.post('/auth/logout')
    commit('clearRootState')
    Object.keys(modules).forEach((module) => {
      commit(`${module}/clearState`)
    })
    commit('logout')
  },

  async updateProjectList({ commit }) {
    const { data: projects } = await axios.get('/projects', {
      params: {
        sortBy: '-updatedAt',
      },
    })
    commit('updateProjectList', projects)
  },

  async getProject({ commit }, projectId) {
    commit('changeProject', projectId)
    const { data: project } = await axios.get('/projects/' + projectId)
    commit('setProject', project)
  },

  async createProject({ state }, project) {
    const toUpdate = pick(project, 'title', 'client', 'description')

    toUpdate.owner = state.user.selectedOrganization
    toUpdate.type = 'contract'

    const { data } = await axios.post('/projects/', toUpdate)
    return data
  },

  async updateProject({ state }, updatedProject) {
    const formattedProject = {
      ...updatedProject,
      startMarker: markerToGeoJson(updatedProject.startMarker),
      endMarker: markerToGeoJson(updatedProject.endMarker),
    }
    await axios.put(`/projects/${state.idproject}`, formattedProject)
  },

  async updateProjectRoles({ state }, newProjectRoles) {
    await axios.post(`/projects/roles/${state.idproject}`, newProjectRoles)
  },

  changeProject({ commit }, idproject) {
    commit('changeProject', idproject)
    commit('updateLastVisited', idproject)
  },

  updateDateList({ commit }, dates) {
    commit('updateDateList', dates)
  },

  async updateDatapointList({ commit }, idproject) {
    const { data } = await axios.get('/datapoints', {
      params: {
        project: idproject,
      },
    })
    commit('updateDatapointList', data.results)
  },

  async updateClientDashboard({ state, commit }, payload) {
    const { uuid, token } = payload
    try {
      const { data } = await axios.get(
        `/passwordless/dashboard/client?uuid=${uuid}&token=${token}`,
      )
      const { filteredDatapoints, date, project } = data

      // Avoid unnecessary mutations if datapoints are the same as before
      const prevDatapoints = state.datapoints
      const currDatapoints = filteredDatapoints
      const difference = differenceBy(currDatapoints, prevDatapoints, '_id')
      if (difference.length === 0) {
        return
      }

      datapointUtil.assemble(filteredDatapoints)
      commit('setIsClientDashboard', true)
      commit('updateDatapointList', filteredDatapoints)
      commit('updateDateList', [date])
      commit('setProject', project)
      commit('updateProjectList', [project])
    } catch (err) {
      console.error(err)
    }
  },

  async updateDashboard({ commit, getters }, info) {
    if (!info?.dashboard || !info?.idproject) return

    const params = {
      project: info.idproject,
    }

    if (info.user) params.user = info.user

    const { data } = await axios.get('/dashboards/' + info.dashboard, {
      params,
    })
    const { datapoints = [], kpi = [], production = [] } = data

    datapointUtil.assemble(datapoints)

    const filteredDatapoints = datapoints.filter((d) => d.date && d.location)
    const notifications = notificationsFromKpis(kpi, getters.userId)

    commit('updateDatapointList', filteredDatapoints)
    commit('updateKpiList', kpi)
    commit('updateProdKpiList', production)
    commit('updateNotifications', notifications)
    commit('updateDateList', sortDatapointDates(filteredDatapoints))
  },

  async updatePMDashboard({ state, commit }, monthYearString) {
    commit('updateNotifications', [])
    // Avoid redundant calls to API
    const isRedundant = state.monthYearList.includes(monthYearString)
    const isNotYearMonth = !isValidYearMonthString(monthYearString)
    if (isRedundant || isNotYearMonth) {
      return
    }

    const { data } = await axios.get(
      '/dashboards/project-manager-quality-control',
      {
        params: { monthYear: monthYearString },
      },
    )
    const { users = [] } = data
    datapointUtil.fillUsers(users)

    const dataList = data.dataList.map((d) => ({
      ...d,
      user: users.find((user) => user._id === d.user),
    }))

    const dates = uniq(dataList.map((d) => d.date))

    commit('addToMonthYearList', monthYearString)
    commit('addToDataList', dataList)
    commit('addToProjectManagerDatePickerList', dates)
  },

  async getClientToken({ state }, payload) {
    const { project, date, user } = payload

    const { data } = await axios.post('/auth/passwordless', {
      project,
      date,
      email: state.user.email,
      user,
    })
    return data
  },

  async getSources() {
    const { data } = await axios.get('/sources')
    return data
  },

  async readNotification({ state }, id) {
    const payload = {
      userId: state.userId,
    }

    await axios.post(`/notifications/read/${id}`, payload)
  },

  async readProductionNotification({ state }, notification) {
    const payload = {
      productType: notification.metadata.productType,
      userId: state.userId,
      kpiName: notification.name,
    }

    await axios.post(`/products/read/${notification.metadata.product}`, payload)
  },

  async forgotPassword(_, email) {
    await axios.post('/auth/forgot-password', { email })
  },

  async modifyPassword(_, payload) {
    const { token, password, path } = payload
    await axios.post(`/auth${path}?token=${token}`, {
      password,
    })
  },

  async updateGMContractorDashboard({ commit }) {
    try {
      const { data } = await axios.get('/dashboards/general-manager-contractor')
      const productionSites = data.productionSitesWithExistingKpis
      const organizationKpis = data.organizationKpis
      commit('updateGMContractorData', { productionSites, organizationKpis })
    } catch (err) {
      console.error(err)
    }
  },
  async updateContractorDashboard({ commit }) {
    const { data } = await axios.get('/dashboards/contractor')

    commit('setContractorKpis', data)
  },

  async updateSiteForemanDashboard({ commit }, { params, superIntendent }) {
    if (!superIntendent) {
      // Set selected date to today, or else weather will be fetched for whatever selectedDate is, which might have been changed in different dashboard
      commit('setSelectedDate', dayjs().format('YYYY-MM-DD'))
    }
    const { data } = await axios.get('/dashboards/site-foreman', { params })
    const { datapoints, kpi, production, iriRuns, images } = data

    datapointUtil.assemble(datapoints)
    const filteredDatapoints = datapoints.filter((d) => d.date && d.location)
    const notifications = notificationsFromKpis(kpi, params.user)

    commit('updateDatapointList', filteredDatapoints)
    commit('updateKpiList', kpi)
    commit('updateNotifications', notifications)
    commit('updateProdKpiList', production)
    commit('iri/setMeasurements', iriRuns, { root: true })
    commit(
      'images/setImages',
      images.filter((image) => image.datapoint?.location?.coordinates?.length),
      { root: true },
    )

    if (superIntendent) {
      commit(
        'updateDateList',
        sortDatapointDates([
          ...filteredDatapoints,
          ...iriRuns.map((run) => ({
            date: toMonthYearDay(run.date),
          })),
          ...images.map((image) => ({
            date: toMonthYearDay(image.datapoint.date),
          })),
        ]),
      )
    }
  },

  async updateTechnicalManagerDashboard() {
    const { data } = await axios.get('/dashboards/technical-manager')
    return data
  },

  async sendSupportMessage({ getters }, message) {
    const { name, email } = getters.user
    await axios.post('/support', { name, email, message })
  },
}
