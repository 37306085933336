<template>
  <div>
    <title-bar>
      <ProjectSelector
        class="col-5"
        @project-change="mixin_onProjectChange"
      />
    </title-bar>
    <v-container
      fluid
      :style="{ height: '100%' }"
      class="flex-column d-flex"
    >
      <v-tabs
        v-model="tab"
        class="flex-grow-0"
      >
        <v-tab
          v-for="item in items"
          :key="item"
        >
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        class="flex-grow-1 fill-width overflow-y-auto"
      >
        <v-tab-item class="fill-height">
          <MultiImageUpload />
        </v-tab-item>
        <v-tab-item class="fill-height">
          <Upload />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import Upload from './Upload.vue'
import MultiImageUpload from './ProjectImageUpload.vue'
import TitleBar from '@/components/core/TitleBar'
import ProjectSelector from '@/components/core/ProjectSelector'
import projectsListMixin from '@/mixins/projectsListMixin'

export default {
  components: {
    Upload,
    MultiImageUpload,
    TitleBar,
    ProjectSelector,
  },
  mixins: [projectsListMixin],
  data() {
    return {
      tab: null,
    }
  },
  computed: {
    items() {
      return [
        this.$t('views.image-upload.multiImageTab'),
        this.$t('views.image-upload.singleImageTab'),
      ]
    },
  },
}
</script>
