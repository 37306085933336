import Vue from 'vue'
import resetState from '@/utils/resetState'
import { set } from '@/utils/vuex'
import { initialIRIState } from './state'

export default {
  update(state, calibrations) {
    Vue.set(state, 'calibrations', calibrations)
  },

  updateDevices(state, devices) {
    Vue.set(state, 'devices', devices)
  },

  updateVehicles(state, vehicles) {
    Vue.set(state, 'vehicles', vehicles)
  },

  setMeasurements(state, measurements) {
    Vue.set(state, 'segments', measurements)
  },

  setGranularity(state, granularity) {
    Vue.set(state, 'granularity', granularity)
  },
  setSegment: set('segment'),
  clearState(state) {
    resetState(state, initialIRIState)
  },
}
