<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    persistent
    style="height: 100%"
    fixed
  >
    <core-spinner v-if="$wait.waiting('isUpdatingClientDashboard')" />
    <v-list v-else-if="isClientDashboard">
      <v-list-item>
        <a href="https://www.dawaysolutions.com"
          ><img
            class="mt-4 mb-2"
            height="68px"
            src="/img/logo-daway.png"
        /></a>
      </v-list-item>
    </v-list>
    <v-list v-else>
      <v-list-item>
        <img
          class="mt-4 mb-2"
          height="68px"
          src="/img/logo-daway.png"
        />
      </v-list-item>
      <v-list-item
        v-for="(link, i) in filteredLinks"
        :key="i"
        :to="link.to"
        :active-class="color"
        class="v-list-item"
      >
        <v-list-item-action>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-title>{{ link.text }}</v-list-item-title>
      </v-list-item>
      <v-divider class="mt-6 mb-6" />
      <v-list-group
        v-if="filteredAdministrationLinks.length > 0"
        prepend-icon="$fas fa-wrench"
        no-action
      >
        <template #activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('core.drawer.links.administration') }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          v-for="(link, i) in filteredAdministrationLinks"
          :key="i + links.length"
          :to="link.to"
          :active-class="color"
          class="v-list-item"
        >
          <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ link.text }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-item
        v-for="(link, i) in tools"
        :key="i + links.length"
        :to="link.to"
        :active-class="color"
        class="v-list-item"
      >
        <v-list-item-action>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-title>{{ link.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <core-weather v-if="requiresWeather" />
    <template slot="append">
      <Copyright class="px-5" />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { roles } from '@/utils/enum'

import Copyright from '@/components/core/Copyright.vue'

export default {
  name: 'CoreDrawer',
  components: {
    Copyright,
  },

  data: () => ({
    responsive: false,
  }),
  computed: {
    ...mapState('app', ['image', 'color']),
    ...mapState(['route', 'isClientDashboard', 'language']),
    ...mapGetters(['userHasRole']),
    requiresWeather() {
      return this.route.meta.requiresWeather
    },
    inputValue: {
      get() {
        return this.$store.state.app.drawer
      },
      set(val) {
        this.setDrawer(val)

        if (val) document.getElementById('square-right').classList.add('hide')
        else document.getElementById('square-right').classList.remove('hide')
      },
    },

    filteredLinks() {
      return this.links.filter(
        (l) =>
          !l.roles ||
          (l.roles.some((r) => this.userHasRole(r)) &&
            (!l.prohibitedRoles ||
              !l.prohibitedRoles.some((r) => this.userHasRole(r)))),
      )
    },

    filteredAdministrationLinks() {
      return this.administrations.filter(
        (l) => !l.roles || l.roles.some((r) => this.userHasRole(r)),
      )
    },
    links() {
      return [
        {
          to: '/dashboard/technical-manager',
          icon: '$fas fa-bars-progress',
          text: this.$t('core.drawer.links.technical-manager'),
          roles: [roles.TECHNICAL_MANAGER],
        },
        {
          to: '/dashboard/site-control-technician',
          icon: '$fas fa-hammer',
          text: this.$t('core.drawer.links.site-control-technician'),
          roles: [roles.SITE_TECHNICIAN],
        },
        {
          to: '/dashboard/general-manager-contractor',
          icon: '$fas fa-pen-ruler',
          text: this.$t('core.drawer.links.general-manager-contractor'),
          roles: [roles.GENERAL_MANAGER_CONTRACTOR],
        },
        {
          to: '/dashboard/site-foreman',
          icon: '$fas fa-hammer',
          text: this.$t('core.drawer.links.site-foreman'),
          roles: [roles.SITE_FOREMAN],
        },
        {
          to: '/dashboard/site-superintendent',
          icon: '$fas fa-hammer',
          text: this.$t('core.drawer.links.site-superintendent'),
          roles: [roles.SITE_SUPERINTENDENT],
        },
        {
          to: '/dashboard/production-foreman',
          icon: '$fas fa-helmet-safety',
          text: this.$t('core.drawer.links.production-foreman'),
          roles: [roles.PRODUCTION_FOREMAN],
        },
        {
          to: '/dashboard/asphalt-foreman',
          icon: '$fas fa-helmet-safety',
          text: this.$t('core.drawer.links.asphalt-foreman'),
          roles: [roles.ASPHALT_FOREMAN],
        },
        {
          to: '/dashboard/aggregate-foreman',
          icon: '$fas fa-helmet-safety',
          text: this.$t('core.drawer.links.aggregate-foreman'),
          roles: [roles.AGGREGATE_FOREMAN],
        },
        {
          to: '/dashboard/production-technician-asphalt',
          icon: '$fas fa-drafting-compass',
          text: this.$t('core.drawer.links.production-technician-asphalt'),
          roles: [roles.PRODUCTION_TECHNICIAN_ASPHALT],
        },
        {
          to: '/dashboard/production-technician-aggregate',
          icon: '$fas fa-drafting-compass',
          text: this.$t('core.drawer.links.production-technician-aggregate'),
          roles: [roles.PRODUCTION_TECHNICIAN_AGGREGATE],
        },
        {
          to: '/dashboard/project-manager',
          icon: '$fas fa-layer-group',
          text: this.$t('core.drawer.links.project-manager'),
          roles: [roles.PROJECT_MANAGER],
        },
        {
          to: '/dashboard/contractor',
          icon: '$fas fa-person-digging',
          text: this.$t('core.drawer.links.contractor'),
          roles: [roles.CONTRACTOR],
        },
        {
          to: '/dashboard/upload-image',
          icon: '$fas fa-image',
          text: this.$t('core.drawer.links.upload-image'),
          roles: [
            roles.SITE_TECHNICIAN,
            roles.SITE_FOREMAN,
            roles.SITE_SUPERINTENDENT,
            roles.PROJECT_MANAGER,
            roles.ROLLING_COMFORT,
            roles.GENERAL_MANAGER_CONTRACTOR,
          ],
        },
        {
          to: '/dashboard/calibration-management',
          icon: '$fas fa-ruler',
          text: this.$t('core.drawer.links.calibration-management'),
          roles: [roles.ROLLING_COMFORT],
        },
        {
          to: '/dashboard/iri-measurements-management',
          icon: '$fas fa-road',
          text: this.$t('core.drawer.links.iri-measurements-management'),
          roles: [roles.ROLLING_COMFORT],
        },
        {
          to: '/dashboard/reports',
          icon: '$far fa-clipboard',
          text: this.$t('core.drawer.links.reports'),
          roles: [
            roles.SITE_TECHNICIAN,
            roles.SITE_FOREMAN,
            roles.SITE_SUPERINTENDENT,
            roles.PROJECT_MANAGER,
            roles.SITE_SUPERVISOR,
            roles.TECHNICAL_MANAGER,
            roles.GENERAL_MANAGER_CONTRACTOR,
          ],
        },
      ]
    },
    tools() {
      return [
        {
          to: '/preferences',
          icon: '$fas fa-cogs',
          text: this.$t('core.drawer.links.preferences'),
        },
        {
          to: '/help',
          icon: '$fas fa-headset',
          text: this.$t('core.drawer.links.help'),
        },
      ]
    },
    administrations() {
      return [
        {
          to: '/administration/projects',
          icon: '$fas fa-layer-group',
          text: this.$t('core.drawer.links.projects'),
          roles: [roles.PROJECT_ADMINISTRATOR],
        },
        {
          to: '/administration/users',
          icon: '$fas fa-users',
          text: this.$t('core.drawer.links.users'),
          roles: [roles.SYSTEM_ADMINISTRATOR, roles.SUPER_ADMIN],
        },
      ]
    },
  },
  mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onClickBtn() {
      this.setDrawer(!this.$store.state.app.drawer)
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    },
  },
}
</script>

<style lang="scss">
#app-drawer {
  .v-list-item__title {
    font-size: 0.9rem !important;
  }

  .v-list__tile {
    border-radius: 4px;

    &--buy {
      margin-top: auto;
      margin-bottom: 17px;
    }
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  .v-image__image--contain {
    top: 9px;
    height: 60%;
  }

  .search-input {
    margin-bottom: 30px !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  .v-responsive__content {
    overflow: auto;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .v-responsive__content::-webkit-scrollbar {
    display: none;
  }

  .v-responsive__content {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
</style>
