<template>
  <l-marker-cluster :options="clusterOptions">
    <l-marker
      v-for="element in images"
      :key="'image-' + element._id"
      :lat-lng="[
        element.datapoint.location.coordinates[1],
        element.datapoint.location.coordinates[0],
      ]"
      @click="() => $emit('click:image', element)"
    >
      <l-icon
        icon-url="/img/fa/solid/fa-image-alt.svg"
        icon-color="black"
        :icon-size="[16, 16]"
      />
    </l-marker>
  </l-marker-cluster>
</template>

<script>
import { icon } from 'leaflet'
import { LIcon, LMarker } from 'vue2-leaflet'
import LMarkerCluster from 'vue2-leaflet-markercluster'

export default {
  name: 'ImageCluster',

  components: {
    LIcon,
    LMarker,
    LMarkerCluster,
  },

  props: {
    images: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      clusterOptions: {
        iconCreateFunction: this.clusterGroupIcon,
        removeOutsideVisibleBounds: true,
        spiderfyOnMaxZoom: false,
        zoomToBoundsOnClick: true,
        disableClusteringAtZoom: 17,
        maxClusterRadius: 60,
      },
    }
  },

  methods: {
    clusterGroupIcon() {
      return icon({
        iconUrl: '/img/fa/solid/fa-images-alt.svg',
        iconSize: [20, 20],
      })
    },
  },
}
</script>
