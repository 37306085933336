var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-wait',{attrs:{"for":"isCalibrationUpdating"}},[_c('core-spinner',{attrs:{"slot":"waiting"},slot:"waiting"}),_c('title-bar'),_c('div',{staticClass:"grid-container"},[_c('IriMap',{staticClass:"calibration-map",attrs:{"map-bindings":_vm.mapBindings},scopedSlots:_vm._u([{key:"map-content",fn:function(){return [_c('iri-run',{attrs:{"runs":_vm.calibrationGeo}}),_c('section-highlight',{attrs:{"section":_vm.selectedSectionGeo}})]},proxy:true}])}),_c('material-card',{staticClass:"fill-height calibration-form",attrs:{"outlined":""}},[_c('v-form',{ref:"calibrationForm"},[_c('v-select',{staticClass:"select-no-border",attrs:{"items":_vm.translatedFileType,"label":_vm.$t('views.upload.placeholder'),"rules":[(v) => !!v || _vm.$t('api-error.iri.datapoint-types-error')]},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}}),_c('v-file-input',{staticClass:"px-2",attrs:{"accept":_vm.validFileExtensions,"rules":[
            (v) =>
              _vm.class1Types.includes(_vm.selectedType)
                ? (!!v && v.length === 1) ||
                  _vm.$t('api-error.iri.calibration-file-error')
                : (!!v && v.length === 3) ||
                  _vm.$t('api-error.iri.calibration-files-error'),
          ],"label":_vm.$t('views.iri-management.upload-form.calibration-files'),"clearable":false,"multiple":"","counter":"","small-chips":""},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),(_vm.isBenchmark)?_c('v-checkbox',{attrs:{"label":_vm.$t('views.iri-management.upload-form.is-public'),"dense":""},model:{value:(_vm.isPublic),callback:function ($$v) {_vm.isPublic=$$v},expression:"isPublic"}}):_vm._e(),(_vm.isCalibration)?_c('div',{staticClass:"select-with-add"},[_c('v-select',{staticClass:"select-no-border",attrs:{"items":_vm.vehicles.map((_v) => ({
                text: `${_v.make} ${_v.model} - ${_v.licencePlate}`,
                value: _v._id,
              })),"label":_vm.$t('views.iri-management.upload-form.vehicle'),"rules":[
              (v) => _vm.isBenchmark || !!v || _vm.$t('api-error.iri.vehicle-error'),
            ]},model:{value:(_vm.selectedVehicle),callback:function ($$v) {_vm.selectedVehicle=$$v},expression:"selectedVehicle"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","elevation":"2"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$fas fa-plus")])],1)]}}],null,false,3254513483),model:{value:(_vm.vehicleDialog),callback:function ($$v) {_vm.vehicleDialog=$$v},expression:"vehicleDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t('views.iri-management.vehicle-form.title')))])]),_c('v-card-text',[_c('v-form',{ref:"vehicleForm",attrs:{"lazy-validation":""}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('views.iri-management.vehicle-form.make'),"required":"","rules":[
                            (v) =>
                              !!v ||
                              _vm.$t(
                                'views.iri-management.vehicle-form.make-error',
                              ),
                          ]},model:{value:(_vm.vehicleMake),callback:function ($$v) {_vm.vehicleMake=$$v},expression:"vehicleMake"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('views.iri-management.vehicle-form.model'),"required":"","rules":[
                            (v) =>
                              !!v ||
                              _vm.$t(
                                'views.iri-management.vehicle-form.model-error',
                              ),
                          ]},model:{value:(_vm.vehicleModel),callback:function ($$v) {_vm.vehicleModel=$$v},expression:"vehicleModel"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                              'views.iri-management.vehicle-form.licence-plate',
                            ),"required":"","rules":[
                            (v) =>
                              !!v ||
                              _vm.$t(
                                'views.iri-management.vehicle-form.licence-plate-error',
                              ),
                          ]},model:{value:(_vm.vehicleLicencePlate),callback:function ($$v) {_vm.vehicleLicencePlate=$$v},expression:"vehicleLicencePlate"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.vehicleDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('views.iri-management.dialog-cancel'))+" ")]),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.saveVehicle}},[_vm._v(" "+_vm._s(_vm.$t('views.iri-management.dialog-save'))+" ")])],1)],1)],1)],1):_vm._e(),(_vm.isCalibration)?_c('div',{staticClass:"select-with-add"},[_c('v-select',{staticClass:"select-no-border",attrs:{"items":_vm.devices.map((_d) => ({
                text: `${_d.externalIdentifier} - ${_d.type}`,
                value: _d._id,
              })),"label":_vm.$t('views.iri-management.upload-form.device'),"rules":[
              (v) => _vm.isBenchmark || !!v || _vm.$t('api-error.iri.device-error'),
            ]},model:{value:(_vm.selectedDevice),callback:function ($$v) {_vm.selectedDevice=$$v},expression:"selectedDevice"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","elevation":"2"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$fas fa-plus")])],1)]}}],null,false,3254513483),model:{value:(_vm.deviceDialog),callback:function ($$v) {_vm.deviceDialog=$$v},expression:"deviceDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t('views.iri-management.device-form.title')))])]),_c('v-card-text',[_c('v-form',{ref:"deviceForm",attrs:{"lazy-validation":""}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"select-no-border",attrs:{"label":_vm.$t('views.iri-management.device-form.device-type'),"required":"","items":_vm.deviceTypes,"rules":[
                            (v) =>
                              !!v ||
                              _vm.$t(
                                'views.iri-management.device-form.device-type-error',
                              ),
                          ]},model:{value:(_vm.selectedDeviceType),callback:function ($$v) {_vm.selectedDeviceType=$$v},expression:"selectedDeviceType"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                              'views.iri-management.device-form.external-identifier',
                            ),"required":"","rules":[
                            (v) =>
                              !!v ||
                              _vm.$t(
                                'views.iri-management.device-form.external-identifier-error',
                              ),
                          ]},model:{value:(_vm.deviceExternalIdentifier),callback:function ($$v) {_vm.deviceExternalIdentifier=$$v},expression:"deviceExternalIdentifier"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deviceDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('views.iri-management.dialog-cancel'))+" ")]),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.saveDevice}},[_vm._v(" "+_vm._s(_vm.$t('views.iri-management.dialog-save'))+" ")])],1)],1)],1)],1):_vm._e(),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.sendFile}},[_vm._v(" "+_vm._s(_vm.$t('views.upload.confirmation'))+" ")])],1),_c('v-btn',{staticClass:"mt-4",on:{"click":function($event){return _vm.$router.push(_vm.$route.path + '/nearby-benchmarks')}}},[_vm._v(" "+_vm._s(_vm.$t('views.iri-management.calibration-section'))+" ")]),_c('v-btn',{staticClass:"mt-4",on:{"click":function($event){return _vm.$router.push(_vm.$route.path + '/calibrations')}}},[_vm._v(" "+_vm._s(_vm.$t('views.iri-management.calibration-list'))+" ")])],1),_c('CalibrationTable',{staticClass:"calibration-table",attrs:{"segments":_vm.calibrations},on:{"item-expanded":_vm.rowExpanded,"section-selected":_vm.sectionSelected}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }