<template>
  <div>
    <core-progress :in-progress="$wait.is('loadingMeasurement')" />
    <title-bar :show-back="true">
      <ProjectSelector
        class="col-5"
        @project-change="mixin_onProjectChange"
      />
    </title-bar>
    <div class="grid-container">
      <div
        v-if="previewMap"
        class="iri-map"
      >
        <MeasurementsMap
          :iri-runs="segments"
          :start-project-location="projectLocation"
          @markerUpdated="markerUpdated($event)"
        />
      </div>
      <div class="iri-form">
        <material-card
          outlined
          class="fill-height"
        >
          <v-form ref="calibrationForm">
            <v-select
              v-model="selectedType"
              :items="translatedFileType"
              :label="$t('views.upload.placeholder')"
              :rules="[(v) => !!v || $t('api-error.iri.datapoint-types-error')]"
              class="select-no-border"
            />
            <v-file-input
              v-model="files"
              :accept="validFileExtensions"
              :rules="[validFile]"
              :label="$t('views.iri-management.upload-form.calibration-files')"
              :clearable="false"
              multiple
              counter
              small-chips
              class="px-4"
              @change="onFileChange"
            />
            <template v-if="preview">
              <v-text-field
                v-model="name"
                :label="$t('enums.headers.name')"
              />
              <v-select
                v-model="selectedLayer"
                :items="roadLayers"
                :label="$t('enums.headers.layer')"
                :rules="[(v) => !!v || $t('api-error.iri.layer-error')]"
                class="select-no-border"
              />
              <v-select
                v-if="!class1Types.includes(selectedType)"
                :items="deviceVehiclePairs"
                :label="
                  $t('views.iri-management.upload-form.device-vehicle-pair')
                "
                :rules="[
                  (v) =>
                    !class1Types.includes(selectedType) ||
                    (!!selectedVehicle && !!selectedDevice) ||
                    $t('api-error.iri.device-vehicle-pair-error'),
                ]"
                class="select-no-border"
                @change="onCalibrationSelect"
              />
              <v-select
                v-model="selectedRoadCategory"
                :items="translatedRoadCategories"
                :label="$t('enums.headers.roadCategory')"
                :rules="[(v) => !!v || $t('api-error.iri.roadCategory-error')]"
                class="select-no-border"
              />
              <v-select
                v-model="selectedDirection"
                :items="roadDirections"
                :label="$t('enums.headers.direction')"
                :rules="[(v) => !!v || $t('api-error.iri.roadDirection-error')]"
                class="select-no-border"
              />
              <v-select
                v-model="selectedLaneNumber"
                :items="laneNumbers"
                :label="$t('enums.headers.laneNumber')"
                :rules="[(v) => !!v || $t('api-error.iri.laneNumber-error')]"
                class="select-no-border"
              />
              <v-text-field
                v-model="sectionStart"
                :label="$t('enums.headers.sectionStart')"
                :hint="$t('enums.headers.sectionInputsHint')"
                :rules="[sectionStartRule]"
              />
              <template v-if="class1Types.includes(selectedType)">
                <v-checkbox
                  v-model="isReference"
                  dense
                >
                  <template #label>
                    <div>
                      {{ $t('views.iri-management.upload-form.is-reference') }}
                    </div>
                  </template>
                </v-checkbox>
                <v-checkbox
                  v-model="isPublic"
                  dense
                >
                  <template #label>
                    <div>
                      {{ $t('views.iri-management.upload-form.is-public') }}
                    </div>
                  </template>
                </v-checkbox>
                <v-checkbox
                  v-model="macrotextureImport"
                  dense
                >
                  <template #label>
                    <div>
                      {{ $t('views.iri-management.checkbox-label') }}
                    </div>
                  </template>
                </v-checkbox>
              </template>
            </template>
            <v-btn
              color="primary"
              :disabled="!previewCompleted"
              @click="sendFile"
            >
              {{ $t('views.upload.confirmation') }}
            </v-btn>
          </v-form>
        </material-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import {
  iriFileTypes,
  roadLayers,
  roadCategories,
  roadDirections,
} from '@/utils/enum'
import { toSelectItems } from '@/utils/i18n'
import TitleBar from '@/components/core/TitleBar'
import MeasurementsMap from './Map.vue'
import ProjectSelector from '../../../core/ProjectSelector.vue'
import { romdasPreProcessing } from '@/utils/mdbUtil'
import { defaultGeolocation } from '@/utils/constants'
import projectsListMixin from '@/mixins/projectsListMixin'

export default {
  name: 'IRIMeasurementCreateView',
  components: {
    MeasurementsMap,
    TitleBar,
    ProjectSelector,
  },

  mixins: [projectsListMixin],

  data() {
    return {
      submitButtonLoading: false,
      macrotextureImport: false,
      isReference: false,
      isPublic: false,
      files: null,
      types: Object.values(iriFileTypes),
      class1Types: [iriFileTypes.romdas],
      roadLayers: toSelectItems(roadLayers, 'enums.layers'),
      selectedLayer: null,
      translatedRoadCategories: toSelectItems(
        roadCategories,
        'enums.roadCategories',
      ),
      translatedFileType: toSelectItems(
        {
          1: iriFileTypes.romdas,
          2: iriFileTypes.roadroid,
          3: iriFileTypes.roadroidSingle,
        },
        'enums.iri-file-types',
      ),
      roadDirections: toSelectItems(roadDirections, 'enums.roadDirections'),
      laneNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      preview: false,
      previewMap: false,
      previewCompleted: false,
      defaultMarkerUpdated: false,
      name: '',
      selectedType: '',
      selectedVehicle: null,
      selectedDevice: null,
      selectedRoadCategory: null,
      selectedDirection: null,
      selectedLaneNumber: null,
      sectionStart: '0+000',
      marker: defaultGeolocation,
    }
  },

  computed: {
    ...mapState('iri', ['calibrations', 'segments']),
    ...mapGetters('iri', ['device', 'vehicle', 'deviceVehiclePairs']),
    ...mapGetters(['projectLocation']),

    validFileExtensions() {
      switch (this.selectedType) {
        case iriFileTypes.romdas:
          return '.mdb'
        case iriFileTypes.roadBump:
          return '.csv'
        case iriFileTypes.roadroid:
        case iriFileTypes.roadroidSingle:
          return '.txt'
        default:
          return ''
      }
    },
  },

  async created() {
    this.updateMeasurements([])
    this.updateCalibration([])
  },

  methods: {
    ...mapActions('iri', [
      'previewIriFile',
      'uploadIriData',
      'uploadCalibration',
      'getCalibrations',
      'getDevices',
      'getVehicles',
    ]),
    ...mapMutations({
      updateCalibration: 'iri/update',
      updateMeasurements: 'iri/setMeasurements',
    }),
    ...mapMutations(['showDanger', 'showSuccess']),

    resetForm() {
      this.macrotextureImport = false
      this.isReference = false
      this.isPublic = false
      this.selectedVehicle = null
      this.selectedDevice = null
      this.selectedLayer = null
      this.selectedRoadCategory = null
      this.selectedDirection = null
      this.selectedLaneNumber = null
      this.sectionStart = '0+000'
      this.marker = defaultGeolocation
      this.defaultMarkerUpdated = false
    },

    async sendFile() {
      try {
        let data
        let file

        if (!this.$refs.calibrationForm.validate()) return

        this.submitButtonLoading = true
        this.$wait.start('loadingMeasurement')

        if (this.selectedType === iriFileTypes.romdas) {
          data = await romdasPreProcessing(this.files[0])
          file = [new Blob([JSON.stringify(data)])]
        } else {
          file = this.files
        }

        await this.uploadIriData({
          files: file,
          type: this.selectedType,
          vehicle: this.selectedVehicle,
          device: this.selectedDevice,
          isReference: this.isReference,
          isPublic: this.isPublic,
          isReversed: data?.isReversed || false,
          layer: this.selectedLayer,
          roadCategory: this.selectedRoadCategory,
          importMacrotexture: this.macrotextureImport,
          direction: this.selectedDirection,
          lane: this.selectedLaneNumber,
          sectionStart: this.sectionStart,
          startLocation: this.defaultMarkerUpdated ? this.marker : undefined,
        })

        this.showSuccess(
          this.$t('views.iri-management.upload-form.upload-file-successful'),
        )
      } finally {
        this.$refs.calibrationForm.reset()

        this.macrotextureImport = false
        this.isReference = false
        this.isPublic = false
        this.submitButtonLoading = false

        this.previewMap = false
        this.preview = false
        this.defaultMarkerUpdated = false

        this.$wait.end('loadingMeasurement')
      }
    },

    async previewFile() {
      try {
        let data
        let files
        this.submitButtonLoading = true
        this.previewMap = false
        this.previewCompleted = false
        this.preview = false

        this.$wait.start('loadingMeasurement')

        if (this.selectedType === iriFileTypes.romdas) {
          data = await romdasPreProcessing(this.files[0])
          files = [new Blob([JSON.stringify(data)])]
        } else {
          files = this.files
        }

        await this.previewIriFile({
          type: this.selectedType,
          files: files,
          vehicle: this.selectedVehicle || undefined,
          device: this.selectedDevice || undefined,
          isReference: false,
          isPublic: false,
          isReversed: data?.isReversed || false,
          layer: this.selectedLayer || undefined,
          roadCategory: this.selectedRoadCategory || undefined,
          importMacrotexture: this.macrotextureImport || undefined,
          direction: this.selectedDirection || undefined,
          lane: this.selectedLaneNumber || undefined,
          sectionStart: this.sectionStart || undefined,
          startLocation: this.defaultMarkerUpdated ? this.marker : undefined,
        })

        if (
          this.selectedType !== iriFileTypes.romdas &&
          this.segments.length > 0
        ) {
          await this.getCalibrations({
            date: this.segments[0].date,
            disabled: false,
          })
        }

        this.showSuccess(
          this.$t('views.iri-management.upload-form.preview-file-successful'),
        )
        this.name = this.segments[0].name
        this.previewMap = true
        this.preview = true
        this.previewCompleted = true
      } finally {
        this.submitButtonLoading = false
        this.$wait.end('loadingMeasurement')
      }
    },

    onFileChange() {
      this.resetForm()

      if (this.files?.length > 0) {
        this.previewFile()
      }
    },

    validFile(v) {
      switch (this.selectedType) {
        case iriFileTypes.romdas:
        case iriFileTypes.roadroidSingle:
          return (
            (!!v && v.length === 1) ||
            this.$t('api-error.iri.calibration-file-error')
          )
        default:
          return (
            (!!v && v.length === 3) ||
            this.$t('api-error.iri.calibration-file-error')
          )
      }
    },

    onCalibrationSelect(calibrationId) {
      const calibration = this.calibrations.find(
        (_c) => _c._id === calibrationId,
      )
      this.selectedDevice = calibration.device
      this.selectedVehicle = calibration.vehicle
    },

    getDeviceVehicleDescription(deviceId, vehicleId) {
      const device = this.device(deviceId)
      const vehicle = this.vehicle(vehicleId)

      if (device && vehicle) {
        return `${device.externalIdentifier} - ${vehicle.make} ${vehicle.model} - ${vehicle.licencePlate}`
      }
    },

    sectionStartRule(value) {
      const pattern = /^\d{1,4}\+\d{3}$/g
      return pattern.test(value) || this.$t('api-error.iri.sectionInput-error')
    },

    markerUpdated(event) {
      this.marker = event
      this.defaultMarkerUpdated = true
    },
  },
}
</script>
<style scoped>
.iri-map {
  grid-row: 1 / span 12;
  grid-column: 1 / span 8;
}
.iri-form {
  grid-row: 1 / span 12;
  grid-column: 9 / span 4;
}
</style>
