<template>
  <v-wait for="loadingDashboard">
    <core-spinner slot="waiting" />
    <title-bar
      :show-back="true"
      :show-title="false"
      :custom-title="segment?.name"
    >
      <GranularitySelector class="col-3" />
    </title-bar>
    <div class="grid-container">
      <iri-map
        v-model="mapBindings"
        class="map"
      >
        <template #map-content>
          <iri-run :runs="iriRunGeo" />
          <section-highlight :section="selectedSectionGeo" />
          <image-cluster
            :images="orderedImages"
            @click:image="imageSelected"
          />
        </template>
      </iri-map>
      <iri-detail-table
        class="table"
        :segment="iriRun"
        :show-image-btn="images.length > 0"
        @section-selected="sectionSelected"
        @click:image="imageSelected"
      />
      <ImageViewer ref="imageViewer" />
    </div>
  </v-wait>
</template>

<script>
import ImageCluster from '@/components/core/map/ImageCluster.vue'
import ImageViewer from '@/components/DashViews/PopUps/ImageViewer'
import IriMap from '@/components/DashViews/Charts/Maps/IriMap'
import IriRun from '@/components/core/map/IriRun.vue'
import IriDetailTable from './IriDetailTable.vue'
import SectionHighlight from '@/components/core/map/SectionHighlight.vue'
import TitleBar from '@/components/core/TitleBar'

import { IRIGranularities } from '@/utils/enum'
import { iriRunToFeatureCollection } from '@/utils/datapoint'
import { defaultMapBindings } from '@/utils/map'
import { center, lineString, nearestPointOnLine, points } from '@turf/turf'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { geoJSON } from 'leaflet'
import GranularitySelector from '@/components/core/GranularitySelector.vue'

export default {
  name: 'IriMeasurementDetailsView',
  components: {
    ImageCluster,
    ImageViewer,
    IriMap,
    IriDetailTable,
    IriRun,
    SectionHighlight,
    TitleBar,
    GranularitySelector,
  },

  props: {
    measurementId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedSection: undefined,
      mapBindings: defaultMapBindings(),
    }
  },

  computed: {
    ...mapGetters('iri', ['contain10mSection']),
    ...mapState('iri', ['granularity', 'segment']),
    ...mapState('images', {
      images: 'items',
    }),

    iriRun() {
      return this.segment
        ? {
            ...this.segment,
            iriSections:
              this.granularity === IRIGranularities.TEN_METERS &&
              this.segment.iriSections10m.length > 0
                ? this.segment.iriSections10m
                : this.segment.iriSections,
          }
        : null
    },

    iriRunGeo() {
      return this.iriRun ? iriRunToFeatureCollection([this.iriRun]) : []
    },

    orderedImages() {
      if (!this.iriRun?.iriSections10m) return []

      let sections = this.iriRun.iriSections10m
      let path = lineString(
        sections.map(
          (s) =>
            center(
              points([s.startLocation.coordinates, s.endLocation.coordinates]),
            ).geometry.coordinates,
        ),
      )

      return this.images
        .filter((i) => i?.datapoint?.location?.coordinates?.length > 0)
        .map((i) => {
          let iriSectionindex = nearestPointOnLine(
            path,
            i.datapoint.location.coordinates,
          ).properties.index

          return {
            ...i,
            endStationInt: sections[iriSectionindex].endStationInt,
            endStation: sections[iriSectionindex].endStation,
            startStation: sections[iriSectionindex].startStation,
            iri: sections[iriSectionindex].iri,
          }
        })
        .sort((a, b) => a.endStationInt - b.endStationInt)
    },

    selectedSectionGeo() {
      if (!this.selectedSection) {
        return null
      } else {
        return {
          type: 'Feature',
          properties: {
            risk: this.selectedSection.RUni,
          },
          geometry: {
            type: 'LineString',
            coordinates: [
              this.selectedSection.startLocation.coordinates,
              this.selectedSection.endLocation.coordinates,
            ],
          },
        }
      }
    },
  },

  async created() {
    this.$wait.start('loadingDashboard')
    await Promise.all([
      this.updateCalibration([]),
      this.getMeasurement({
        measurementId: this.measurementId,
      }),
      this.getCalibrations({ disabled: false }),
      this.getVehicles(),
      this.getDevices(),
    ])
    this.setBounds()
    this.$wait.end('loadingDashboard')
  },

  methods: {
    ...mapActions('iri', [
      'getCalibrations',
      'getDevices',
      'getVehicles',
      'getMeasurement',
    ]),
    ...mapMutations({
      updateCalibration: 'iri/update',
    }),

    imageSelected(item) {
      if (item) {
        this.$refs.imageViewer.openMultiple(
          this.orderedImages,
          this.orderedImages.findIndex((i) => i._id === item._id),
        )
      } else {
        this.$refs.imageViewer.openMultiple(this.orderedImages, 0)
      }
    },

    sectionSelected(item) {
      this.selectedSection = item
    },

    setBounds() {
      const bounds = geoJSON(this.iriRunGeo).getBounds()

      if (bounds && bounds.isValid()) {
        this.mapBindings.bounds = geoJSON(this.iriRunGeo).getBounds()
      }
    },
  },
}
</script>

<style scoped>
.granularity {
  grid-column: 9 / span 4;
}
.map {
  grid-row: 1 / span 6;
  grid-column: 1 / span 12;
}
.table {
  grid-row: 7 / span 6;
  grid-column: 1 / span 12;
}
</style>
