import { IRIGranularities } from '@/utils/enum'
import { defaults } from 'lodash'

export const initialIRIState = {
  calibrations: [],
  devices: [],
  vehicles: [],
  segments: [],
  segment: null,
  granularity: IRIGranularities.HUNDRED_METERS,
}

export default defaults({}, initialIRIState)
