<template>
  <v-wait for="isFetchingCalibrations">
    <core-spinner slot="waiting" />
    <title-bar :show-back="true" />
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <ListTable
            :title="$t('views.iri-management.calibration-list-title')"
            :description="
              $t('views.iri-management.calibration-list-description')
            "
            :headers="[
              {
                text: $t(
                  'views.iri-management.calibration-list-header.calibration',
                ),
                value: 'iriOffset',
                sortable: true,
              },
              {
                text: $t('views.iri-management.calibration-list-header.device'),
                value: 'device',
                sortable: true,
              },
              {
                text: $t(
                  'views.iri-management.calibration-list-header.vehicle',
                ),
                value: 'vehicle',
                sortable: true,
              },
              {
                text: $t('views.iri-management.calibration-list-header.date'),
                value: 'date',
                sortable: true,
              },
              {
                text: $t(
                  'views.iri-management.calibration-list-header.enabled',
                ),
                value: 'enabled',
                sortable: false,
                checkbox: true,
                onChange: updateDisabledCalibration,
              },
            ]"
            :items="calibrationItems"
            :hidden="[]"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-wait>
</template>

<script>
import ListTable from '../../Charts/Tables/List'
import { mapActions, mapMutations, mapState } from 'vuex'
import TitleBar from '@/components/core/TitleBar'
import { mapWaitingActions } from 'vue-wait'

export default {
  name: 'CalibrationsDashboard',
  components: {
    ListTable,
    TitleBar,
  },

  computed: {
    ...mapState('iri', ['calibrations']),

    calibrationItems() {
      return this.calibrations.map((_c) => ({
        id: _c._id,
        date: { value: new Date(_c.date).toLocaleString('fr-CA') },
        iriOffset: { value: _c.iriOffset },
        device: {
          value: `${_c.device.externalIdentifier} - ${_c.device.type}`,
        },
        vehicle: {
          value: `${_c.vehicle.make} ${_c.vehicle.model} - ${_c.vehicle.licencePlate}`,
        },
        enabled: { value: !_c.disabled },
      }))
    },
  },

  created() {
    this.updateCalibration([])
    this.getCalibrations()
  },

  methods: {
    ...mapActions({
      patchCalibration: 'iri/updateCalibration',
    }),
    ...mapWaitingActions({
      getCalibrations: {
        action: 'iri/getCalibrations',
        loader: 'isFetchingCalibrations',
      },
    }),
    ...mapMutations({
      updateCalibration: 'iri/update',
    }),
    updateDisabledCalibration(id, enabled) {
      this.patchCalibration({ id, disabled: !enabled })
    },
  },
}
</script>

<style scoped></style>
